import { useEffect, useMemo, useState } from 'react';
import InputForm from './InputForm';
import MainChart from './MainChart';
import SubChart from './SubChart';
import Promo from './model/Promo';
import DailyPromo from './model/DailyPromo';

export default function MonthPoint({
  year, month
}: {
  year: number, month: number
}) {

  const [allPromoArray, setAllPromoArray] = useState<Promo[]>([]);

  const monthId = year + '-' + ('0' + month).slice(-2);
  const saveLabel = 'r-' + monthId;

  const [rank, setRank] = useState<string>('ダイヤモンド');
  const [selectedPromoIdArray, setSelectedPromoIdArray] = useState<string[]>([]);

  useEffect(() => {
    fetch(`/rakuten/r-${monthId}.json`)
      .then(res => res.json())
      .then(data => {
        const newAllPromoArray: Promo[] = data;
        setAllPromoArray(newAllPromoArray);

        load(newAllPromoArray);
      })
      .catch((e) => {
        const errorMsg = 'キャンペーン情報を取得できませんでした。例外情報=' + e.toString();
        console.error(errorMsg);
        alert(errorMsg);
        return;
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    save();
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [rank, selectedPromoIdArray]
  );

  // for InputForm
  const selectablePromoArray = useMemo(() =>
    allPromoArray.filter(p => !p.rank || p.rank === rank),
    [allPromoArray, rank]
  );

  // for MainChart
  const [dailyPromo, setDailyPromo] = useState<DailyPromo>();
  const mainChartPromoArray = useMemo(() =>
    selectablePromoArray.filter(p => selectedPromoIdArray.includes(p.id)).filter(p => 0 < p.pointPercent),
    [selectablePromoArray, selectedPromoIdArray]
  );

  mainChartPromoArray.forEach((p, i) => p.color = colorArray[i % colorArray.length]);

  return (
    <div>
      <InputForm
        allPromoArray={allPromoArray}
        selectablePromoArray={selectablePromoArray}
        rank={rank} setRank={setRank}
        selectedPromoIdArray={selectedPromoIdArray} setSelectedPromoIdArray={setSelectedPromoIdArray}
        setDailyPromo={setDailyPromo}
        year={year} month={month}
        monthId={monthId}
      />
      <MainChart
        promoArray={mainChartPromoArray}
        setDailyPromo={setDailyPromo}
        year={year} month={month}
      />
      <SubChart
        dailyPromo={dailyPromo}
      />
    </div>
  );

  function save() {
    if (allPromoArray.length === 0) {
      return;
    }

    const saveObj: SaveObj = {};

    saveObj.rank = rank;

    const promosObj: { [key: string]: { i: string, s: boolean } } = {};
    allPromoArray.forEach(p => promosObj[p.id] = ({ i: p.id, s: selectedPromoIdArray.includes(p.id) }));
    saveObj.promosObj = promosObj;

    const json = JSON.stringify(saveObj);
    localStorage.setItem(saveLabel, json);
  }

  function load(newAllPromoArray: Promo[]) {
    const saveObj = loadCore();
    if (saveObj.rank) {
      setRank(saveObj.rank);
    }
    const savedPromosObj = saveObj.promosObj ? saveObj.promosObj : {};
    const newSelectedPromoIdArray = newAllPromoArray
      .filter(p => !p.exclusiveLabel)
      .filter(p => savedPromosObj[p.id] ? savedPromosObj[p.id].s : p.default)
      .map(p => p.id);

    const exclusiveLabelArray = Array.from(
      new Set(newAllPromoArray
        .filter(p => p.exclusiveLabel)
        .map(p => p.exclusiveLabel)));
    exclusiveLabelArray.forEach(label => {
      const sameLabelPromoIdArray = newAllPromoArray.filter(p => p.exclusiveLabel === label);
      // バグ(入力データやプログラム)で2つ以上が該当することもあるが、その場合にも1つ選ぶ
      let promo = sameLabelPromoIdArray.find(p => savedPromosObj[p.id]?.s);
      if (!promo) {
        promo = sameLabelPromoIdArray.find(p => p.default);
      }
      if (!promo) {
        // 入力データのdefalt指定が正しければココにくることはない
        promo = sameLabelPromoIdArray[0];
      }
      newSelectedPromoIdArray.push(promo.id);
    });

    setSelectedPromoIdArray(newSelectedPromoIdArray);
  }

  function loadCore(): SaveObj {
    const json = localStorage.getItem(saveLabel);
    if (!json) {
      return {};
    }
    const saveObj = JSON.parse(json);
    return saveObj;
  }
}

interface SaveObj {
  rank?: string;
  promosObj?: { [key: string]: { i: string, s: boolean } };
}

const colorArray = [
  // ライトトーン
  '#54C3F1',
  '#EF845C',
  '#6C9BD2',
  '#F9C270',
  '#796BAF',
  '#FFF67F',
  '#BA79B1',
  '#C1DB81',
  '#EE87B4',
  '#69BD83',
  '#EF858C',
  '#61C1BE',
  // ライトグレイッシュトーン
  '#CF7250',
  '#49AAD2',
  '#D7A861',
  '#5D87B7',
  '#DED46E',
  '#695C98',
  '#A7BE70',
  '#A1689A',
  '#5AA572',
  '#CE749C',
  '#53A8A6',
  '#CF737A',
  // ソフトトーン
  '#AAC863',
  '#A55B9A',
  '#39A869',
  '#DC669B',
  '#27ACA9',
  '#DD6673',
  '#00AEE0',
  '#DE6641',
  '#E8AC51',
  '#4784BF',
  '#F2E55C',
  '#5D5099',
  // ペールグレイッシュトーン
  '#D4987E',
  '#89BCD6',
  '#D9BA8C',
  '#8DA3C4',
  '#DED799',
  '#8F85AF',
  '#B9C998',
  '#B491B2',
  '#8FB896',
  '#D39CB5',
  '#8CBBB8',
  '#D49A9C',
];